const modalSizes = [
  { value: 'modal-sm', text: 'Small' },
  { value: 'modal-md', text: 'Default' },
  { value: 'modal-lg', text: 'Large' },
  { value: 'modal-xl', text: 'Extra large' },
  { value: 'modal-xxl', text: 'Double extra large' },
  { value: 'modal-fullscreen', text: 'Fullscreen' },
];

export default modalSizes;
