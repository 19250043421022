<template>
  <b-row class="mb-4">
    <b-col cols="12" offset-sm="1" sm="3">
      <label :for="handle">{{ label }}</label>
    </b-col>
    <b-col cols="12" sm="7">
      <b-form-checkbox
        :id="handle"
        :name="handle"
        size="lg"
        v-model="isChecked"
        @input="updateField"
      >
        {{ checkboxLabel }}
      </b-form-checkbox>
      <b-form-select
        v-if="isChecked"
        v-model="selectedValue"
        :options="selectOptions"
        class="mt-2"
        @change="updateField"
      ></b-form-select>
      <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
      <b-form-text v-if="desc && isChecked" v-html="desc"></b-form-text>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'CheckboxSelect',
  props: {
    valueFromDb: { type: [String, null], default: null },
    handle: { type: String, default: null },
    label: { type: String, default: null },
    checkboxLabel: { type: String, default: null },
    desc: { type: String, default: null },
    options: { type: Array, default: () => [] },
  },
  data() {
    return {
      isChecked: false,
      selectedValue: null,
    };
  },
  computed: {
    selectOptions() {
      return [
        { value: null, text: this.$t('settings.pleaseSelect') },
        ...this.options,
      ];
    },
  },
  created() {
    this.isChecked = this.valueFromDb !== null;
    this.selectedValue = this.valueFromDb;
  },
  methods: {
    updateField() {
      this.$emit('update-field', this.isChecked ? this.selectedValue : null);
    },
  },
};
</script>
