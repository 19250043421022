var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "mb-4" },
    [
      _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }, [
        _c("label", [_vm._v(_vm._s(_vm.label))])
      ]),
      _c(
        "b-col",
        { attrs: { cols: "12", sm: "7" } },
        [
          _c(
            "b-form-group",
            [
              _c("b-form-radio-group", {
                attrs: { options: _vm.options },
                on: { change: _vm.handleChange },
                model: {
                  value: _vm.selectedOption,
                  callback: function($$v) {
                    _vm.selectedOption = $$v
                  },
                  expression: "selectedOption"
                }
              })
            ],
            1
          ),
          _vm.selectedOption === "timeout"
            ? _c("b-form-group", { staticClass: "mt-2" }, [
                _c(
                  "div",
                  { staticClass: "d-flex align-items-center" },
                  [
                    _c("b-form-input", {
                      staticClass: "timeout-input",
                      attrs: { type: "number" },
                      on: { input: _vm.handleTimeoutInput },
                      model: {
                        value: _vm.timeoutValue,
                        callback: function($$v) {
                          _vm.timeoutValue = _vm._n($$v)
                        },
                        expression: "timeoutValue"
                      }
                    }),
                    _c("span", { staticClass: "ml-2" }, [_vm._v("ms")])
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm.selectedOption === "scrollPercent"
            ? _c("b-form-group", { staticClass: "mt-2" }, [
                _c(
                  "div",
                  { staticClass: "d-flex align-items-center" },
                  [
                    _c("b-form-input", {
                      staticClass: "scroll-input",
                      attrs: { type: "range", min: "0", max: "100", step: "1" },
                      on: { input: _vm.handleScrollInput },
                      model: {
                        value: _vm.scrollValue,
                        callback: function($$v) {
                          _vm.scrollValue = _vm._n($$v)
                        },
                        expression: "scrollValue"
                      }
                    }),
                    _c("span", { staticClass: "ml-2" }, [
                      _vm._v(_vm._s(_vm.scrollValue) + "%")
                    ])
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm.desc
            ? _c("b-form-text", { domProps: { innerHTML: _vm._s(_vm.desc) } })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }