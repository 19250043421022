<template>
  <b-row class="mb-4">
    <b-col cols="12" offset-sm="1" sm="3">
      <label :for="handle">{{ label }}</label>
    </b-col>
    <b-col cols="12" sm="7">
      <b-form-select
        :id="handle"
        v-model="selectedValue"
        :options="selectOptions"
        @change="$emit('update-field', $event)"
      ></b-form-select>
      <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
      <b-form-text v-if="desc" v-html="desc"></b-form-text>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'SelectRow',
  props: {
    valueFromDb: { type: [String, Number], default: null },
    handle: { type: String, required: true },
    label: { type: String, required: true },
    desc: { type: String, default: null },
    options: { type: Array, required: true },
  },
  data() {
    return {
      selectedValue: null,
    };
  },
  computed: {
    selectOptions() {
      return [
        { value: null, text: this.$t('settings.pleaseSelect') },
        ...this.options,
      ];
    },
  },
  created() {
    this.selectedValue = this.valueFromDb;
  },
};
</script>
