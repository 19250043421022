<template>
  <b-row class="mb-4">
    <b-col cols="12" offset-sm="1" sm="3">
      <label :for="handle">{{ label }}</label>
    </b-col>
    <b-col cols="12" sm="7">
      <treeselect
        :id="handle"
        v-model="selectedValues"
        :options="treeOptions"
        :multiple="true"
        :flatten-search-results="true"
        :value-consists-of="'LEAF_PRIORITY'"
        :placeholder="$t('settings.plugins.leadModal.selectPage')"
        @input="$emit('update-field', $event)"
      />
      <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
      <b-form-text v-if="desc" v-html="desc"></b-form-text>
    </b-col>
  </b-row>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
  name: 'MultiTreeSelectRow',
  components: { Treeselect },
  props: {
    valueFromDb: { type: Array, default: () => [] },
    handle: { type: String, required: true },
    label: { type: String, required: true },
    desc: { type: String, default: null },
    options: { type: Array, required: true },
  },
  data() {
    return {
      selectedValues: [],
    };
  },
  computed: {
    treeOptions() {
      return [
        {
          id: 'all',
          label: this.$t('settings.plugins.leadModal.all'),
          children: this.transformOptions(this.options),
        },
      ];
    },
  },
  created() {
    this.selectedValues = this.valueFromDb || [];
  },
  methods: {
    transformOptions(options) {
      return options.map((option) => ({
        id: option,
        label: option,
      }));
    },
  },
};
</script>
