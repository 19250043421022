<template>
  <div class="lead-modal-slide">
    <div class="header d-flex w-100">
      <div class="slide-header order" v-b-toggle="`collapse-${index}`">{{ index + 1 }}</div>
      <div class="slide-header name" v-b-toggle="`collapse-${index}`">{{ configData.name }}</div>
      <div class="dot-menu">
        <b-dropdown
          toggle-class="dot-button"
          no-caret
          dropup
          no-flip
          right
        >
          <template #button-content>
            <b-icon icon="three-dots-vertical" aria-hidden="true" scale=".8"></b-icon>
          </template>
          <b-dropdown-item
            @click="$emit('open-rename-modal', index)"
          >
            {{ $t('settings.plugins.leadModal.rename') }}
            <b-icon icon="pencil-square" aria-hidden="true" scale=".8"></b-icon>
          </b-dropdown-item>
          <b-dropdown-item
            @click="$emit('delete-item', index)"
          >
            Delete
            <b-icon icon="trash" aria-hidden="true" scale=".8"></b-icon>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <b-collapse
      :id="`collapse-${index}`"
      class="pt-3"
      v-model="isCollapseOpen"
      @show="isCollapseOpen = true"
      @hidden="isCollapseOpen = false"
    >
      <div v-if="isCollapseOpen">
        <component
          v-for="(configItem, configIndex) in pluginFieldConfig"
          :key="configIndex"
          :is="configItem.fieldType"
          :handle="configItem.handle + '___' + index"
          :label="configItem.title"
          :desc="configItem.desc"
          :enter="configItem.enter"
          :options="configItem.options"
          :checkboxLabel="configItem.checkboxLabel"
          :value-from-db="leadModalSlideData[configItem.handle]"
          @update-field="updateSettingValue($event, configItem.handle)"
        />
      </div>
    </b-collapse>
  </div>
</template>

<script>
import modalSizes from '@/defaultData/modalSizes';
import InputRow from '@/components/helper/InputRow.vue';
import CheckboxRow from '@/components/helper/CheckboxRow.vue';
import CheckboxRowWithTitle from '@/components/helper/CheckboxRowWithTitle.vue';
import SelectRow from '@/components/helper/SelectRow.vue';
import MultiTreeSelectRow from '@/components/helper/MultiTreeSelectRow.vue';
import CheckboxSelect from '@/components/helper/CheckboxSelect.vue';
import RadioInputRow from '@/components/helper/RadioInputRow.vue';

export default {
  name: 'PluginLeadModalRowSlide',
  components: {
    InputRow,
    CheckboxRow,
    CheckboxRowWithTitle,
    SelectRow,
    MultiTreeSelectRow,
    CheckboxSelect,
    RadioInputRow,
  },
  props: {
    configData: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
    closeCollapsible: {
      type: Boolean,
      default: false,
    },
    childPageSlugs: {
      type: Array,
    },
  },
  data() {
    return {
      isCollapseOpen: false,
      pluginFieldConfig: [
        {
          title: this.$t('settings.plugins.leadModal.slug'),
          fieldType: 'SelectRow',
          handle: 'slug',
          desc: this.$t('settings.plugins.leadModal.slugDesc'),
          label: 'Slug',
          options: this.childPageSlugs,
          enter: false,
        },
        {
          title: this.$t('settings.plugins.leadModal.showOnSlug'),
          fieldType: 'MultiTreeSelectRow',
          handle: 'showOnSlug',
          desc: this.$t('settings.plugins.leadModal.showOnSlugDesc'),
          label: 'Multi-Tree Select',
          options: this.childPageSlugs,
        },
        {
          title: this.$t('settings.plugins.leadModal.oneTimeLabel'),
          fieldType: 'CheckboxRow',
          handle: 'oneTime',
        },
        {
          title: this.$t('settings.plugins.leadModal.hideModal'),
          fieldType: 'RadioInputRow',
          handle: 'hideModal',
          desc: this.$t('settings.plugins.leadModal.hideModalDesc'),
        },
        {
          title: this.$t('settings.plugins.leadModal.size'),
          fieldType: 'SelectRow',
          handle: 'size',
          desc: this.$t('settings.plugins.leadModal.sizeDesc'),
          label: 'Size',
          enter: false,
          options: modalSizes,
        },
        {
          title: this.$t('settings.plugins.leadModal.sizeTablet'),
          fieldType: 'CheckboxSelect',
          handle: 'sizeTablet',
          desc: this.$t('settings.plugins.leadModal.sizeTabletDesc'),
          checkboxLabel: this.$t('settings.plugins.leadModal.sizeTabletCheckboxLabel'),
          options: modalSizes,
        },
        {
          title: this.$t('settings.plugins.leadModal.sizeMobile'),
          fieldType: 'CheckboxSelect',
          handle: 'sizeMobile',
          desc: this.$t('settings.plugins.leadModal.sizeMobileDesc'),
          checkboxLabel: this.$t('settings.plugins.leadModal.sizeMobileCheckboxLabel'),
          options: modalSizes,
        },
        {
          title: this.$t('settings.plugins.leadModal.center'),
          fieldType: 'CheckboxRowWithTitle',
          checkboxLabel: this.$t('settings.plugins.leadModal.centerLabel'),
          handle: 'center',
        },
      ],
      settingsData: null,
    };
  },
  created() {
    this.settingsData = { ...this.configData };
  },
  watch: {
    closeCollapsible(val) {
      if (val) {
        this.isCollapseOpen = false;
      }
      this.$emit('collapsible-closed', false);
    },
    configData(val) {
      this.settingsData = { ...val };
    },
  },
  computed: {
    leadModalSlideData() {
      return {
        ...this.configData,
        hideModal: {
          timeout: this.configData.timeout === null ? null : this.configData.timeout,
          scrollPercent: this.configData.scrollPercent === null ? null : this.configData.scrollPercent,
        },
      };
    },
  },
  methods: {
    updateSettingValue(value, handle) {
      if (handle === 'hideModal') {
        this.settingsData.timeout = value.timeout;
        this.settingsData.scrollPercent = value.scrollPercent;
      } else {
        this.settingsData[handle] = value;
      }
      this.$emit('update-setting-row', this.settingsData, this.index);
    },
  },
};
</script>

<style lang="scss" scoped>
.lead-modal-slide {
  border: 1px solid #D0D4D8;
  padding: 7px;
  margin-bottom: 8px;

  .header {
    div {
      flex-basis: 30px;
      text-align: center;
    }

    .name {
      flex-grow: 2;
      text-align: left;
    }

    .navigation {
      flex-basis: 10px;
      svg {
        cursor: pointer;
      }
    }
  }
}

/deep/ .dropdown .btn-secondary {
  background-color: #fff;
  border-color: #fff;
  color: #000;
  padding: 0;
  line-height: 10px;

  &:hover {
    background-color: #fff;
    border-color: #fff;
    color: #000;
  }
}

/deep/ .dropdown-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #e0e2e4;

  svg {
    padding-left: 5px;
    margin-left: 5px;
  }
}
</style>
