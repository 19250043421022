<template>
  <b-row class="mb-4">
    <b-col cols="12" offset-sm="1" sm="3">
      <label>{{ label }}</label>
    </b-col>
    <b-col cols="12" sm="7">
      <b-form-group>
        <b-form-radio-group
          v-model="selectedOption"
          :options="options"
          @change="handleChange"
        ></b-form-radio-group>
      </b-form-group>

      <b-form-group v-if="selectedOption === 'timeout'" class="mt-2">
        <div class="d-flex align-items-center">
          <b-form-input
            v-model.number="timeoutValue"
            type="number"
            @input="handleTimeoutInput"
            class="timeout-input"
          ></b-form-input>
          <span class="ml-2">ms</span>
        </div>
      </b-form-group>

      <b-form-group v-if="selectedOption === 'scrollPercent'" class="mt-2">
        <div class="d-flex align-items-center">
          <b-form-input
            v-model.number="scrollValue"
            type="range"
            min="0"
            max="100"
            step="1"
            @input="handleScrollInput"
            class="scroll-input"
          ></b-form-input>
          <span class="ml-2">{{ scrollValue }}%</span>
        </div>
      </b-form-group>

      <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
      <b-form-text v-if="desc" v-html="desc"></b-form-text>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'RadioInputRow',
  props: {
    valueFromDb: { type: Object, default: () => ({}) },
    handle: { type: String, default: null },
    label: { type: String, default: null },
    desc: { type: String, default: null },
  },
  data() {
    return {
      selectedOption: 'timeout',
      timeoutValue: null,
      scrollValue: null,
      options: [
        { text: this.$t('settings.plugins.leadModal.timeout'), value: 'timeout' },
        { text: this.$t('settings.plugins.leadModal.scrollPercent'), value: 'scrollPercent' },
      ],
    };
  },
  created() {
    if (this.valueFromDb) {
      this.timeoutValue = this.valueFromDb.timeout === null ? null : this.valueFromDb.timeout;
      this.scrollValue = this.valueFromDb.scrollPercent === null ? null : this.valueFromDb.scrollPercent;
      this.selectedOption = this.valueFromDb.timeout !== null ? 'timeout' : 'scrollPercent';
    }
  },
  methods: {
    handleChange() {
      if (this.selectedOption === 'timeout' && this.timeoutValue === null) {
        this.timeoutValue = 0;
      } else if (this.selectedOption === 'scrollPercent' && this.scrollValue === null) {
        this.scrollValue = 0;
      }
      this.emitUpdate();
    },
    handleTimeoutInput() {
      this.emitUpdate();
    },
    handleScrollInput() {
      this.emitUpdate();
    },
    emitUpdate() {
      const value = {
        timeout: this.selectedOption === 'timeout' ? this.timeoutValue : null,
        scrollPercent: this.selectedOption === 'scrollPercent' ? this.scrollValue : null,
      };
      this.$emit('update-field', value);
    },
  },
};
</script>

<style scoped>
.timeout-input {
  width: 100px;
}
.scroll-input {
  width: 200px;
}
</style>
